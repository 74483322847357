const fn = {
  computed: {
    exchangeRates() {
      if (!this.$store.state.exchangeRates) return
      return this.$store.state.exchangeRates.exchangeRates.pairs
    }
  },
  methods: {
    truncateAddress(address) {
      if (!address) return
      return `${address.substring(0, 6)}...${address.substring(
        address.length - 4
      )}`
    },
    roundAmount(amount, decimals = 3) {
      let tralala = Math.pow(10, decimals)
      return Math.round(amount * tralala) / tralala
    },
    convert(value, base = 'ETH', quote = 'USD', decimals = 3, pairs) {
      if (base == quote) return fn.methods.roundAmount(value, decimals)
      let rate = false
      if (pairs) rate = pairs[`${base}_${quote}`] // so i can use it in partners.js
      else rate = this.exchangeRates ? this.exchangeRates[`${base}_${quote}`] : false
      if (!rate) return 0
      let result = fn.methods.roundAmount(rate * value, decimals)
      return result ? result : 0
    },
    copyToClipboard(content) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(content).then(
          () => {
            console.log("Copied to clipboard successfully.")
          },
          (err) => {
            console.log("Failed to copy the text to clipboard.", err)
          }
        )
      } else if (window.clipboardData) {
        window.clipboardData.setData("Text", content)
      }
    },
  },
}

export default fn