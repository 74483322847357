import {firestoreAction} from "vuexfire"
import {db, fb} from "@/globals"

const state = () => ({
  user: null,
  auth: {},
  loadingState: {
    auth: "init",
  },
})

// getters
const getters = {}

// actions
const actions = {
  bindAuth: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      let userId = payload.uid
      if (!payload.email.endsWith('@cuddle.me')) return
      commit("setLoadingState", {instance: "auth", state: "loading"})
      try {
        var user = await bindFirestoreRef("auth", db.doc(`auth/${userId}`), {
          wait: false,
        })
      } catch (e) {
        console.error("user error:", e)
      }

      // console.log('user:', user)

      // if no user found, create one
      if (!user) {
        await dispatch("createUser")
      }

      commit("setLoadingState", {instance: "auth", state: "loaded"})
      return
    }
  ),
  unbindAuth: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("auth")
  }),

  setUser({commit}, payload) {
    commit('setUser', payload)
  },

  async createUser({commit, state, rootState}, payload) {
    let user = fb.auth().currentUser
    console.log("createUser:", user)
    let response = await db
      .doc(`auth/${user.uid}`)
      .set({
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        meta: {
          created: fb.firestore.FieldValue.serverTimestamp(),
          updated: false,
          deleted: false,
        },
      })
      .catch((e) => {
        console.error("error", e)
      })
    console.log("response", response)
    return
  },
}

// mutations
const mutations = {
  setLoadingState(state, payload) {
    state.loadingState[payload.instance] = payload.state
  },
  setUser(state, payload) {
    state.user = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
