<template>
  <div
    class="select"
    :class="[{disabled: disabled}, `mode-${mode}`]"
  >
    <!-- <div
    class='label'
    v-if="label"
  >
    <span v-html="label"></span>
  </div> -->

    <label for="label" class="block text-sm font-medium text-gray-700 dark:text-gray-500">{{
      label
    }}</label>

    <div class="selected-option" @click="toggleOpen()">
      <label
        class="select-label"
        v-if="selectedOption && selectedOption.name"
        >{{ getOptionName(selectedOption.name) }}</label
      >
      <label class="select-label" v-if="!selectedOption">---</label>

      <!-- <div class='arrow' >
      <oneIcon icon="ChevronDown" v-if="!open" key='chevron-down' />
      <oneIcon icon="ChevronUp" v-if="open" key='chevron-up' />
    </div> -->
      <!-- <oneButtonFold
        v-show="!this.disabled && this.mode == 'edit'"
        :folded="open"
      /> -->
    </div>

    <div class="options" v-if="open">
      <div
        class="item"
        v-for="option in options"
        :class="{selected: value == option.value}"
        :key="`option-${valueKey ? option.value[valueKey] : option.value}`"
        @click="selectOption(option.value)"
      >
        <div class="radio-wrap">
          <label
            class="select-label"
            v-if="option.key"
            v-localize="{key: option.key}"
          ></label>
          <label class="select-label" v-if="option.name">{{
            getOptionName(option.name)
          }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Select",
  props: {
    value: {
      type: [Object, String, Number],
    },
    // allows values to be objects
    valueKey: {
      type: [String, Boolean],
      default: false,
    },
    path: {
      type: String,
    },
    label: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "edit",
    },
    size: {
      type: [String, Number],
      default: "m",
    },
    currentLang: {
      type: String,
    },
  },
  components: {},
  data() {
    return {
      open: false,
    }
  },
  computed: {
    currentValue() {
      return this.value && this.path ? this.value[this.path] : this.value
    },
    selectedOption() {
      return this.options.find((o) =>
        this.valueKey
          ? o.value[this.valueKey] == this.currentValue[this.valueKey]
          : o.value == this.currentValue
      )
    },
  },
  created() {},
  methods: {
    toggleOpen() {
      if (this.disabled) return false
      this.open = !this.open
    },
    getOptionName(name) {
      if (typeof name === "object" && name !== null && this.currentLang) {
        return name[this.currentLang]
      } else {
        return name
      }
    },
    selectOption(value) {
      if (this.disabled) return false
      // console.log('selectOption', value)
      this.open = false

      // write to supplied object
      if (this.value && this.path) {
        this.value[this.path] = value
        // this.$emit('input')
        this.$emit("input", value, {
          fieldId: this._uid,
        })
      }
      // return only
      else {
        console.log("selectOption()", value)
        // this.$emit('input', value)
        this.$emit("input", value, {
          fieldId: this._uid,
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
.select {
  position: relative;

  &.disabled {
    .selected-option {
      cursor: not-allowed;
      background: none;
      border: 1px solid #ff0000;

      label.select-label {
        color: #ff0000;
      }

      &:hover {
        background: none;
      }
    }
  }

  &.mode-view {
    .selected-option {
      background: none;
      pointer-events: none;
      padding: 0 0 0 0px;

      label.select-label {
        color: theme('colors.gray.500');
      }
    }
  }

  // .label {
  //     text-align: center;
  //     width: 100%;
  //     padding: 15px 0 10px;
  //     font-weight: bold;
  //     text-transform: uppercase;
  //     letter-spacing: 0.1rem;
  //     font-size: 0.8rem;
  //     display: block;
  //
  //     span {}
  // }

  span.label {
    padding-left: 8px;
    color: #ff0000;
  }

  .selected-option {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: theme('colors.gray.300');
    border-radius: 7px;
    margin: 4px 0;
    cursor: pointer;
    overflow: hidden;
    padding: 14px;

    &:hover {
      background-color: theme('colors.gray.500');
    }
  }

  .options {
    background-color: theme('colors.white');
    border-radius: 7px;
    position: absolute;
    width: 100%;
    z-index: 900;
    max-height: 200px;
    overflow-y: scroll;

    .item {
      width: 100%;
      // padding: 20px;
      // background-color: #ff0000;
      margin: 0 0 0 0;
      cursor: pointer;
      overflow: hidden;

      &:first {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
      }

      &:last {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }

      &:hover {
        background-color: theme('colors.gray.300');
      }

      &.selected {
        background-color: theme('colors.gray.700');
        height: auto;

        label.select-label {
          color: #ffffff;
        }

        .radio-wrap {
        }
      }

      &.disabled {
        background-color: #ff0000;
        cursor: not-allowed;

        label.radio-label {
          color: #ff0000;
        }

        .radio-wrap {
        }
      }

      .radio-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 14px;
      }

      input {
        display: none;
      }
    }
  }

  label.select-label {
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.05rem;
    color: theme('colors.gray.700');
    pointer-events: none;
    text-transform: none;
  }
}
</style>
