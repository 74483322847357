import Vue from "vue"

// also in store/index.js
if (
  ["local_dev", "development", "staging"].includes(
    process.env.VUE_APP_ENVIRONMENT
  ) ||
  process.env.VUE_APP_FORCE_DEBUG == "true"
) {
  Vue.config.devtools = true
  Vue.config.productionTip = false
  Vue.config.silent = false
} else if (["production"].includes(process.env.VUE_APP_ENVIRONMENT)) {
  Vue.config.devtools = false
  Vue.config.productionTip = false
  Vue.config.silent = false
}

import App from "./App.vue"
import router from './router'
import store from "./store"
import 'firebaseui/dist/firebaseui.css'
import './assets/tailwind.css'
import './assets/firebaseui-override.less'
import OnoffToggle from 'vue-onoff-toggle'

Vue.use(OnoffToggle)

// register base components globally so we don't have have to import them manually
const requireComponent = require.context('@/components/base', true, /\w+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)
  const componentName = 'Base' + fileName.split('/').pop().replace(/\.\w+$/, '')
  // console.log('registering component:', componentName)
  Vue.component(componentName, componentConfig.default)
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
