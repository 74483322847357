<template>
<div class='p-2'>
  <div class="flex-shrink-0 flex bg-gray-700 rounded-lg p-4" v-if="user">
    <div class="flex-shrink-0 w-full group block">
      <div class="flex items-center">
        <div>
          <img
            class="inline-block h-9 w-9 rounded-full"
            :src="user.photoURL"
            alt=""
            v-if="user.photoURL"
          />
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium text-white">
            {{ user.displayName }}
          </p>
          <a
          href="#"
            class="text-xs font-medium text-gray-300 group-hover:text-gray-200"
            @click="logout"
          >
            logout
          </a>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {fb, fbui} from "@/globals"

export default {
  name: "User",
  props: {},
  components: {},
  data() {
    return {
      user: null,
    }
  },
  created() {
    fb.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user
      }
    })
  },
  methods: {
    logout(e) {
      e.stopPropagation()
      fb.auth().signOut()
      this.$router.push({
        name: "auth",
      })
    },
  },
}
</script>

<style scoped lang="less"></style>
