import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import createPersistedState from 'vuex-persistedstate'

// also config in main.js
console.log('VUE_APP_FORCE_DEBUG', process.env.VUE_APP_FORCE_DEBUG)
if (['local_dev', 'development', 'staging'].includes(process.env.VUE_APP_ENVIRONMENT) || process.env.VUE_APP_FORCE_DEBUG == "true") {
  Vue.config.devtools = true
  Vue.config.productionTip = false
  Vue.config.silent = false
} else if (['production'].includes(process.env.VUE_APP_ENVIRONMENT)) {
  Vue.config.devtools = false
  Vue.config.productionTip = false
  Vue.config.silent = false
}

import frontend from './modules/frontend'
import auth from './modules/auth'
import partners from './modules/partners'
import donations from './modules/donations'
import exchangeRates from './modules/exchangeRates'
import stats from './modules/stats'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['auth']
  })],
  modules: {
    frontend,
    auth,
    partners,
    donations,
    exchangeRates,
    stats
  },
  mutations: vuexfireMutations
})

export default store