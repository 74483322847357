import {firestoreAction} from "vuexfire"
import {db, fb} from "@/globals"

const state = () => ({
  donations: [],
  loadingState: {
    donations: "init",
  },
})

// getters
const getters = {}

// actions
const actions = {
  bind: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      commit("setLoadingState", {instance: "donations", state: "loading"})

      await bindFirestoreRef("donations", db.collectionGroup(`partnerTransactions`), {
        wait: false,
      })

      commit("setLoadingState", {instance: "donations", state: "loaded"})
      return
    }
  ),
  unbind: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("donations")
  }),
}

// mutations
const mutations = {
  setLoadingState(state, payload) {
    state.loadingState[payload.instance] = payload.state
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
