import Vue from "vue"
import Router from "vue-router"
import Store from "@/store"
import {fb, fbui} from "@/globals"
import Dashboard from "../views/Dashboard.vue"
import Auth from "../views/Auth.vue"

Vue.use(Router)

const routes = [
  {
    path: "/auth",
    name: "auth",
    component: Auth,
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/partners",
    name: "partners",
    component: () => import("../views/Partners.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ":partnerId",
        name: "partner",
        component: () => import("../views/Partner.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: "/donations",
    name: "donations",
    component: () => import("../views/Donations.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: ":donationId",
        name: "donation",
        component: () => import("../views/Donation.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
  {
    path: "/users",
    name: "users",
    component: () => import("../views/Users.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      requiresAuth: true,
    },
  },
]

const router = new Router({
  mode: "history",
  routes,
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  let user = Store.state.auth.user
  // if user is not from @cuddle.me, just loggoff immediately and go back to auth
  if (requiresAuth && (!user || !user.email || !user.email.endsWith('@cuddle.me'))) {
    fb.auth().signOut()
    next("auth")
  } else {
    next()
  }
})

export default router
