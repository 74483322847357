<template>
  <div>
    <div
      class="
        sticky
        top-0
        z-10
        md:hidden
        pl-1
        pt-1
        sm:pl-3 sm:pt-3
        bg-gray-100
        dark:bg-gray-800
      "
    >
      <button
        type="button"
        class="
          -ml-0.5
          -mt-0.5
          h-12
          w-12
          inline-flex
          items-center
          justify-center
          rounded-md
          text-gray-500
          hover:text-gray-900
          focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500
        "
        @click="menuOpen = true"
      >
        <MenuIcon class="h-6 w-6" />
      </button>
    </div>

    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <div
      class="fixed inset-0 flex z-40 md:hidden"
      role="dialog"
      aria-modal="true"
      v-show="menuOpen"
    >
      <div class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
        <!--
        Close button, show/hide based on off-canvas menu state.

        Entering: "ease-in-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in-out duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button
            type="button"
            class="
              cls
              ml-1
              flex
              items-center
              justify-center
              h-10
              w-10
              rounded-full
              focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
            "
            @click="menuOpen = false"
          >
            <span class="sr-only">Close sidebar</span>
            <XIcon class="h-6 w-6 text-white" />
          </button>
        </div>

        <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
          <div class="flex-shrink-0 flex items-center px-4">
            <CuddleLogo class="w-12" />
          </div>
          <nav class="mt-5 px-2 space-y-1">
            <div v-for="link in links" :key="link.id">
              <router-link
                :to="link.to"
                class="
                  group
                  flex
                  items-center
                  px-2
                  py-2
                  text-base
                  font-medium
                  rounded-md
                "
                :class="[
                  {'bg-gray-900 text-white': link.highlightRoutes.includes($route.name)},
                  {'text-gray-300': !link.highlightRoutes.includes($route.name)},
                ]"
                @click="menuOpen = false"
              >
                <component
                  :is="link.icon"
                  class="text-gray-300 mr-4 flex-shrink-0 h-6 w-6"
                />
                {{ link.title }}
              </router-link>
            </div>
          </nav>
        </div>
      </div>

      <div class="flex-shrink-0 w-14">
        <!-- Force sidebar to shrink to fit close icon -->
      </div>
    </div>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="
          flex-1 flex flex-col
          min-h-0
          bg-gray-100
          dark:bg-gray-900
          terminal:border-r
          dark:border-gray-800
        "
      >
        <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          <div class="flex items-center flex-shrink-0 px-4">
            <CuddleLogo class="w-12" />
          </div>
          <nav class="mt-5 flex-1 px-2 space-y-1">
            <div v-for="link in links" :key="link.id">
              <router-link
                :to="link.to"
                class="
                  group
                  flex
                  items-center
                  px-2
                  py-2
                  text-sm
                  font-medium
                  rounded-md
                  hover:bg-gray-400
                  hover:text-white
                "
                :class="[
                  {
                    'bg-gray-400 dark:bg-gray-700 text-white':
                    link.highlightRoutes.includes($route.name)
                      ,
                  },
                  {'text-gray-500': !link.highlightRoutes.includes($route.name)},
                ]"
                ><component
                  :is="link.icon"
                  class="text-gray-300 mr-4 flex-shrink-0 h-6 w-6"
                />
                {{ link.title }}
              </router-link>
            </div>
          </nav>
        </div>

        <User />
      </div>
    </div>
  </div>
</template>

<script>
import {
  HomeIcon,
  HeartIcon,
  XIcon,
  CogIcon,
  MenuIcon,
  UsersIcon,
  CurrencyDollarIcon
} from "@vue-hero-icons/outline"
import User from "./User.vue"
import CuddleLogo from "@/assets/cuddle_logo_icon_color.svg?inline"

export default {
  name: "Sidebar",
  props: {},
  components: {
    CuddleLogo,
    HomeIcon,
    HeartIcon,
    CogIcon,
    XIcon,
    UsersIcon,
    CurrencyDollarIcon,
    MenuIcon,
    User,
  },
  data() {
    return {
      menuOpen: false,
      links: [
        {
          id: "dashboard",
          title: "dashboard",
          route: "dashboard",
          highlightRoutes: ['dashboard'],
          to: "/",
          icon: "HomeIcon",
        },
        {
          id: "partners",
          title: "partners",
          route: "partners",
          highlightRoutes: ['partners', 'partner'],
          to: "/partners",
          icon: "HeartIcon",
        },
        {
          id: "donations",
          title: "donations",
          route: "donations",
          highlightRoutes: ['donations'],
          to: "/donations",
          icon: "CurrencyDollarIcon",
        },
        // {
        //   id: "users",
        //   title: "users",
        //   route: "users",
        //   highlightRoutes: ['users', 'user'],
        //   to: "/users",
        //   icon: "UsersIcon",
        // },
        // {
        //   id: "settings",
        //   title: "settings",
        //   route: "settings",
        //   highlightRoutes: ['settings'],
        //   to: "/settings",
        //   icon: "CogIcon",
        // },
      ],
    }
  },
  computed: {
    auth() {
      return this.$store.state.auth.auth
    },
  },
}
</script>

<style scoped lang="less"></style>
