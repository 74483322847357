import {firestoreAction} from "vuexfire"
import {db, fb} from "@/globals"
import mixinGlobal from '@/mixins/mixinGlobal'

const state = () => ({
  partners: [],
  partnerPrivate: {},
  loadingState: {
    partners: "init",
  },
})

// getters
const getters = {
  totalPartners: (state, getters, rootState) => {
    let total = 0
    state.partners.map((p) => {
      if (!p.demo) total++
    })
    return total
  },

  totalDonationsCount: (state, getters, rootState) => {
    let total = 0
    if (state.partners && state.partners.length > 0) {
      state.partners.map((p) => {
        if (!p.demo && !isNaN(parseInt(p.totalDonationsCount)))
          total += parseInt(p.totalDonationsCount)
      })
    }
    return total
  },

  totalETH: (state, getters, rootState) => {
    return mixinGlobal.methods.roundAmount(
      state.partners.reduce(function (a, b) {
        if (b.demo) return a
        else return a + b.totalDonations.ETH
      }, 0)
    )
  },

  totalUSD: (state, getters, rootState) => {
    if (rootState.exchangeRates.loadingState.exchangeRates != "loaded") return
    return mixinGlobal.methods.roundAmount(
      state.partners.reduce(function (a, b) {
        if (b.demo) return a
        else return a + mixinGlobal.methods.convert(b.totalDonations.ETH, 'ETH', 'USD', 3, rootState.exchangeRates.exchangeRates.pairs)
      }, 0),
      0
    )
  },
}

// actions
const actions = {
  bind: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      console.log('binding partners')
      commit("setLoadingState", {instance: "partners", state: "loading"})

      await bindFirestoreRef("partners", db.collection(`partners`), {
        wait: false,
      })

      commit("setLoadingState", {instance: "partners", state: "loaded"})
      return
    }
  ),
  unbind: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("partners")
  }),

  bindPartnerPrivate: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      commit("setLoadingState", {instance: "partnerPrivate", state: "loading"})
      // console.log("binding partner private", payload)
      await bindFirestoreRef(
        "partnerPrivate",
        db.doc(`partners/${payload.partnerId}/private/default`),
        {
          wait: false,
        }
      )

      commit("setLoadingState", {instance: "partnerPrivate", state: "loaded"})
      return
    }
  ),
  unbindPartnerPrivate: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("partners")
  }),

  async update({commit, state, rootState}, payload) {
    let promises = []
    let publicData = {
      "meta.updated": fb.firestore.FieldValue.serverTimestamp(),
    }
    if (payload.partner) {
      publicData.name = payload.partner.name.trim()
      publicData.ethAddress = payload.partner.ethAddress.trim().toLowerCase()
      publicData.websiteURL = payload.partner.websiteURL.trim()
      publicData.country = payload.partner.country
      publicData.kind = payload.partner.kind
    }
    
    if (payload.uploadedLogos) {
      console.log('logos:', payload.uploadedLogos)
      publicData.logos = payload.uploadedLogos
    }

    let updatePublic = db.doc(`partners/${payload.partner.id}`).update(publicData)
    promises.push(updatePublic)

    if (payload.partnerPrivate) {
    let updatePrivate = db
      .doc(`partners/${payload.partner.id}/private/default`)
      .update({
        bankAccount: payload.partnerPrivate.bankAccount.trim(),
        note: payload.partnerPrivate.note,
        "meta.updated": fb.firestore.FieldValue.serverTimestamp(),
      })
      promises.push(updatePrivate)
    }

    await Promise.all(promises)
    return
  },

  async delete({commit, state, rootState}, payload) {
    let deletePartner = db.doc(`partners/${payload.id}`).delete()
    let deletePartnerPrivate = db
      .doc(`partners/${payload.id}/private/default`)
      .delete()
    // should also delete partner TX, but screw that now
    await Promise.all([deletePartner, deletePartnerPrivate])
    return
  },

  async create({commit, state, rootState}, payload) {
    let newPartnerRef = db.collection("partners").doc()
    let newPartnerPrivateRef = db.doc(
      `partners/${newPartnerRef.id}/private/default`
    )
    let createPartner = newPartnerRef.set({
      name: "",
      ethAddress: "",
      websiteURL: "",
      totalDonations: {ETH: 0},
      latestDonations: [],
      demo: false,
      logos: [],
      meta: {
        created: fb.firestore.FieldValue.serverTimestamp(),
        updated: false,
        deleted: false,
      },
    })

    let createPartnerPrivate = newPartnerPrivateRef.set({
      bankAccount: "",
      note: "",
      meta: {
        created: fb.firestore.FieldValue.serverTimestamp(),
        updated: false,
        deleted: false,
      },
    })

    await Promise.all([createPartner, createPartnerPrivate])

    return newPartnerRef.id
  },
}

// mutations
const mutations = {
  setLoadingState(state, payload) {
    state.loadingState[payload.instance] = payload.state
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
