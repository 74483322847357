<template>
  <div class="">
    <div>
      <div v-if="route == 'auth'">
        <router-view />
      </div>
      <div v-if="route != 'auth'">
        <Sidebar />
        <div class="md:pl-64 flex flex-col flex-1">
          <main class="flex-1">
            <div class="py-6">
              <div class="mx-auto px-4 sm:px-6 md:px-8">
                <router-view />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue"
import {fb, fbui} from "@/globals"

export default {
  name: "App",
  components: {
    Sidebar,
  },
  data() {
    return {
      authChecked: false,
    }
  },
  computed: {
    route() {
      return this.$route.name
    },
  },
  async created() {
    fb.auth().onAuthStateChanged(async (user) => {
      if (user && user.email && user.email.endsWith("@cuddle.me")) {
        this.$store.dispatch("auth/setUser", user)

        await Promise.all([
          this.$store.dispatch("exchangeRates/bind"),
          this.$store.dispatch("auth/bindAuth", user),
        ])
      } else {
        this.$store.dispatch("auth/setUser", null)
        if (this.$route.name != "auth") {
          this.$router.push({
            name: "auth",
          })
        }
      }
    })

    // await Promise.all([
    //   // this.$store.dispatch("funds/bind"),
    // ])
  },
}
</script>

<style lang="less"></style>
