<template>
  <div>
    <div class='flex flex-row justify-start items-end'><h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-white" v-if="title">{{title}}</h3>
    <span class="pl-3 text-xs text-gray-400 dark:text-gray-600 block" v-if="updatedTimestamp == 'live'">live</span>
    <span class="pl-3 text-xs text-gray-400 dark:text-gray-600 block" v-else>{{ timeAgo(updatedTimestamp) }}</span>
    </div>
    <dl class="mt-5 grid gap-4" :class="[`grid-cols-${cols}`,`sm:grid-cols-${colsSm}`]">
      <div
        v-for="item in stats"
        :key="item.name"
        class="px-4 py-5 bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden sm:p-6"
      >
        <dt class="text-sm font-medium text-gray-500 truncate">
          {{ item.name }}
        </dt>
        <dd class="mt-1 text-3xl font-semibold text-gray-900 dark:text-white">
          <span v-if="!loading">{{ item.stat }}</span>
          <BaseLoading size="s" :center="false" v-if="loading" />
        </dd>
      </div>
    </dl>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"
import TimeAgo from "javascript-time-ago"
import TimeAgoLocaleEn from "javascript-time-ago/locale/en.json"

export default {
  name: "Stats",
  mixins: [mixinGlobal],
  props: {
    title: String,
    stats: Array,
    updatedTimestamp: [Date, String],
    cols: {
      type: Number,
      default: 2
    },
    colsSm: {
      type: Number, 
      default: 4
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  data() {
    return {
      timeUpdater: null,
      currentTime: null,
    }
  },
  created() {
    TimeAgo.addDefaultLocale(TimeAgoLocaleEn)
    this.updateTime()
  },
  beforeDestroy() {
    clearInterval(this.timeUpdater)
  },
  methods: {
    updateTime() {
      this.timeUpdater = setInterval(() => {
        this.currentTime = new Date()
      }, 1000)
    },
    timeAgo(timestamp) {
      if (!timestamp) return
      const timeAgo = new TimeAgo("en-US")
      return timeAgo.format(timestamp, "round")
    },
  },
}
</script>

<style scoped lang="less"></style>
