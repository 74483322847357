import {firestoreAction} from "vuexfire"
import {db} from "@/globals"

const state = () => ({
  theme: "terminal", // terminal | modern
  loadingState: {
    funds: "init",
  },
})

// getters
const getters = {}

// actions
const actions = {
  setTheme({commit, state, rootState}, payload) {
    if (payload == 'terminal') document.body.classList.add('terminal')
    else if (payload == 'modern') document.body.classList.remove('terminal')
    commit("setTheme", payload)
  },
}

// mutations
const mutations = {
  setTheme(state, payload) {
    state.theme = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
