<template>
  <div class='w-screen h-screen flex justify-center items-center text-center'>
    <div class=''>
    <!-- <span class='font-bold text-3xl dark:text-gray-200'>cuddle</span> -->
    <CuddleLogo class='w-48 mx-auto' />
    <span class="block mt-4 text-3xl font-bold text-cdlpink">admin</span>
    <section class='mt-16' id="firebaseui-auth-container"></section>
    </div>
  </div>
</template>

<script>
import {fb, fbui} from "@/globals"
import CuddleLogo from '@/assets/cuddle_logo_color.svg?inline'

export default {
  name: "Auth",
  components: {
    CuddleLogo
  },
  created() {},
  mounted() {
    let ui = firebaseui.auth.AuthUI.getInstance()
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(fb.auth())
    }
    var uiConfig = {
      signInSuccessUrl: "/",
      signInOptions: [fb.auth.GoogleAuthProvider.PROVIDER_ID],
      signinFlow: 'popup' // default is 'redirect', but doesn't work in icognito tab
    }
    ui.start("#firebaseui-auth-container", uiConfig)
  },
}
</script>
