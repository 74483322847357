import {firestoreAction} from "vuexfire"
import {db, fb} from "@/globals"

const state = () => ({
  discord: {},
  googleAnalytics: {},
  twitter: {},
  loadingState: {
    discord: "init",
    googleAnalytics: "init",
    twitter: "init",
  },
})

// getters
const getters = {}

// actions
const actions = {
  bindDiscord: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      console.log('binding stats/discord')
      commit("setLoadingState", {instance: "discord", state: "loading"})
      await bindFirestoreRef("discord", db.doc(`statsDiscord/latest`), {
        wait: false,
      })
      commit("setLoadingState", {instance: "discord", state: "loaded"})
      return
    }
  ),
  unbindDiscord: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("discord")
  }),

  bindGoogleAnalytics: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      console.log('binding stats/googleAnalytics')
      commit("setLoadingState", {instance: "googleAnalytics", state: "loading"})
      await bindFirestoreRef("googleAnalytics", db.doc(`statsGoogleAnalytics/latest`), {
        wait: false,
      })
      commit("setLoadingState", {instance: "googleAnalytics", state: "loaded"})
      return
    }
  ),
  unbindGoogleAnalytics: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("twitter")
  }),

  bindTwitter: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      console.log('binding stats/twitter')
      commit("setLoadingState", {instance: "twitter", state: "loading"})
      await bindFirestoreRef("twitter", db.doc(`statsTwitter/latest`), {
        wait: false,
      })
      commit("setLoadingState", {instance: "twitter", state: "loaded"})
      return
    }
  ),
  unbindTwitter: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("twitter")
  }),
}

// mutations
const mutations = {
  setLoadingState(state, payload) {
    state.loadingState[payload.instance] = payload.state
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
