<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="
            shadow
            overflow-hidden
            border-b border-gray-200
            dark:border-gray-900
            sm:rounded-lg
          "
        >
          <table
            class="min-w-full divide-y divide-gray-200 dark:divide-gray-600"
          >
            <!-- head -->
            <thead class="bg-gray-50 dark:bg-gray-800">
              <tr v-for="(row, theadTrIndex) in table.head" :key="theadTrIndex">
                <th
                  :class="[
                    tc.head.th,
                    `text-${col.align ? col.align : 'right'}`,
                  ]"
                  v-for="(col, theadTrTdIndex) in row"
                  :key="theadTrTdIndex"
                >
                  {{ col.c }}
                </th>
              </tr>
            </thead>

            <!-- body: loading -->
            <tbody v-if="loading">
              <tr>
                <td :colspan="table.head[0].length" class="p-6">
                  <BaseLoading size="s" />
                </td>
              </tr>
            </tbody>

            <!-- body: empty -->
            <tbody v-if="!loading && table.body.length === 0">
              <tr>
                <td :colspan="table.head[0].length" class="p-6 text-center">
                  no cuddles here 😔
                </td>
              </tr>
            </tbody>

            <!-- body: data -->
            <tbody
              class="
                bg-white
                dark:bg-gray-700
                divide-y divide-gray-200
                dark:divide-gray-600
              "
              v-if="!loading"
            >
              <tr
                class="hover:bg-gray-50 dark:hover:bg-gray-800"
                v-for="(row, tbodyTrIndex) in table.body"
                :key="tbodyTrIndex"
              >
                <td
                  :class="[
                    tc.body.td,
                    {'text-gray-300 dark:text-gray-500': col.ignored},
                    {'text-gray-500 dark:text-gray-300': !col.ignored},
                    {'w-0 pr-0': col.warning},
                    {'font-mono': col.mono},
                    `text-${
                      table.head[0][tbodyTrTdIndex].align
                        ? table.head[0][tbodyTrTdIndex].align
                        : 'right'
                    }`,
                  ]"
                  v-for="(col, tbodyTrTdIndex) in row"
                  :key="tbodyTrTdIndex"
                >
                <span :class="[{'cursor-pointer hover:text-gray-700 dark:hover:text-gray-50': col.routerLink},{'cursor-copy hover:text-gray-300': col.clickToCopy}]" @click="onClick(col)">
                  <span v-if="col.truncate">{{ truncateAddress(col.c) }}</span>
                  <span v-else-if="col.warning === true">⚠️</span>
                  <span v-else-if="col.warning === false"></span>
                  <span v-else-if="!col.c && col.c !== 0">?</span>
                  <span v-else>{{ col.c }}</span>
                </span>
                  <!-- <div
                    class="
                      text-sm text-gray-500
                      dark:text-gray-300
                      cursor-pointer
                      hover:text-gray-700
                      dark:hover:text-gray-50
                    "
                  >
                  </div> -->
                </td>
              </tr>
            </tbody>

            <!-- foot: data -->
            <tfoot v-if="!loading">
              <tr class="bg-gray-50 dark:bg-gray-800" v-for="(row, tfootTrIndex) in table.foot" :key="tfootTrIndex">
                <td
                  :class="[
                    tc.foot.td,
                    `text-${
                      table.head[0][tfootTrTdIndex].align
                        ? table.head[0][tfootTrTdIndex].align
                        : 'right'
                    }`,
                  ]"
                  v-for="(col, tfootTrTdIndex) in row"
                  :key="tfootTrTdIndex"
                >
                  {{ col.c }}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"

export default {
  name: "Table",
  mixins: [mixinGlobal],
  props: {
    table: Object,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
  data() {
    return {
      tc: {
        head: {
          th: "px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider",
        },
        body: {
          td: "px-6 py-4 whitespace-nowrap text-sm",
        },
        foot: {
          td: "px-6 py-2 font-bold whitespace-nowrap text-sm",
        },
      },
    }
  },
  methods: {
    onClick(col) {
      if (col.clickToCopy) this.copyToClipboard(col.c)
      if (col.routerLink) {
        this.$router.push(col.routerLink)
      }
    }
  }
}
</script>

<style scoped lang="less">
.cursor-copy {
  cursor: copy;
}
</style>
