import {firestoreAction} from "vuexfire"
import {db, fb} from "@/globals"

const state = () => ({
  exchangeRates: {},
  loadingState: {
    exchangeRates: "init",
  },
})

// getters
const getters = {}

// actions
const actions = {
  bind: firestoreAction(
    async ({commit, state, dispatch, bindFirestoreRef}, payload) => {
      commit("setLoadingState", {instance: "exchangeRates", state: "loading"})

      await bindFirestoreRef("exchangeRates", db.doc(`exchangeRates/latest`), {
        wait: false,
      })

      commit("setLoadingState", {instance: "exchangeRates", state: "loaded"})
      return
    }
  ),
  unbind: firestoreAction(({unbindFirestoreRef}) => {
    unbindFirestoreRef("exchangeRates")
  }),
}

// mutations
const mutations = {
  setLoadingState(state, payload) {
    state.loadingState[payload.instance] = payload.state
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
