<template>
  <div>
    <div>
      <BaseStats
        class=""
        title="Partners"
        :stats="partnerStats"
        :loading="loadingPartners"
        updatedTimestamp="live"
      />
      <div class="mt-6 flex flex-row justify-start">
        <BaseStats
          class="w-1/2 pr-2"
          title="Discord"
          :cols="1"
          :colsSm="1"
          :stats="discordStats"
          :loading="loadingDiscord"
          :updatedTimestamp="statsDiscordUpdatedTimestamp"
        />
        <BaseStats
          class="w-1/2 pl-2"
          title="Twitter"
          :cols="1"
          :colsSm="1"
          :stats="twitterStats"
          :loading="loadingTwitter"
          :updatedTimestamp="statsTwitterUpdatedTimestamp"
        />
      </div>
      <BaseStats
        class="mt-6"
        title="Website"
        :stats="websiteStats"
        :loading="loadingGoogleAnalytics"
        :updatedTimestamp="statsGoogleAnalyticsUpdatedTimestamp"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {},
  // created() {},
  async created() {
    await Promise.all([
      this.$store.dispatch("exchangeRates/bind"),
      this.$store.dispatch("partners/bind"),
      this.$store.dispatch("stats/bindDiscord"),
      this.$store.dispatch("stats/bindGoogleAnalytics"),
      this.$store.dispatch("stats/bindTwitter"),
    ])
  },
  computed: {
    loadingPartners() {
      return this.$store.state.partners.loadingState.partners != "loaded"
    },
    loadingDiscord() {
      return this.$store.state.stats.loadingState.discord != "loaded"
    },
    loadingTwitter() {
      return this.$store.state.stats.loadingState.twitter != "loaded"
    },
    loadingGoogleAnalytics() {
      return this.$store.state.stats.loadingState.googleAnalytics != "loaded"
    },
    totalPartners() {
      return this.$store.getters["partners/totalPartners"]
    },
    totalDonationsCount() {
      return this.$store.getters["partners/totalDonationsCount"]
    },
    totalETH() {
      return this.$store.getters["partners/totalETH"]
    },
    totalUSD() {
      return this.$store.getters["partners/totalUSD"]
    },
    statsDiscord() {
      if (this.loadingDiscord) return false
      return this.$store.state.stats.discord
    },
    statsDiscordUpdatedTimestamp() {
      if (
        !this.statsDiscord ||
        !this.statsDiscord.meta ||
        !this.statsDiscord.meta.updated ||
        !this.statsDiscord.meta.updated.seconds
      )
        return
      return new Date(
        this.statsDiscord.meta.updated.seconds * 1000 +
          this.statsDiscord.meta.updated.nanoseconds / 1000000
      )
    },
    statsGoogleAnalytics() {
      if (this.loadingGoogleAnalytics) return false
      return this.$store.state.stats.googleAnalytics
    },
    statsGoogleAnalyticsUpdatedTimestamp() {
      if (
        !this.statsGoogleAnalytics ||
        !this.statsGoogleAnalytics.meta ||
        !this.statsGoogleAnalytics.meta.updated ||
        !this.statsGoogleAnalytics.meta.updated.seconds
      )
        return
      return new Date(
        this.statsGoogleAnalytics.meta.updated.seconds * 1000 +
          this.statsGoogleAnalytics.meta.updated.nanoseconds / 1000000
      )
    },
    statsTwitter() {
      if (this.loadingTwitter) return false
      return this.$store.state.stats.twitter
    },
    statsTwitterUpdatedTimestamp() {
      if (
        !this.statsTwitter ||
        !this.statsTwitter.meta ||
        !this.statsTwitter.meta.updated ||
        !this.statsTwitter.meta.updated.seconds
      )
        return
      return new Date(
        this.statsTwitter.meta.updated.seconds * 1000 +
          this.statsTwitter.meta.updated.nanoseconds / 1000000
      )
    },
    discordStats() {
      if (!this.statsDiscord) return [{}]
      return [{name: "members", stat: this.statsDiscord.snapshot.memberCount}]
    },
    twitterStats() {
      if (!this.statsTwitter) return [{}]
      return [
        {
          name: "followers",
          stat: this.statsTwitter.snapshot.public_metrics.followers_count,
        },
      ]
    },
    websiteStats() {
      if (!this.statsGoogleAnalytics) return [{}, {}, {}, {}]
      return [
        {
          name: "users today",
          stat: this.statsGoogleAnalytics.metrics.today.totalUsers,
        },
        {
          name: "users yesterday",
          stat: this.statsGoogleAnalytics.metrics.yesterday.totalUsers,
        },
        {
          name: "users last 7 days",
          stat: this.statsGoogleAnalytics.metrics.last7days.totalUsers,
        },
        {
          name: "users last 28 days",
          stat: this.statsGoogleAnalytics.metrics.last28days.totalUsers,
        },
      ]
    },
    partnerStats() {
      return [
        {name: "count", stat: this.totalPartners},
        {name: "donations", stat: this.totalDonationsCount},
        {name: "total ETH", stat: this.totalETH},
        {
          name: "total USD",
          stat: this.totalUSD ? this.totalUSD.toLocaleString() : "",
        },
      ]
    },
  },
}
</script>
