<template>
  <div>
    <label for="label" class="block text-sm font-medium text-gray-700 dark:text-gray-500">{{
      label
    }}</label>
    <div class="mt-1">
      <input
        :type="type"
        :readonly="mode == 'view'"
        name="label"
        class="
          block
          w-full
          sm:text-sm
          rounded-md
        "
        :class="[{'bg-transparent px-0 py-1 border-none shadow-none dark:text-gray-200 focus:ring-0 focus:border-none': mode == 'view'}, {'bg-white dark:bg-gray-700 shadow-sm border-gray-300 dark:border-gray-500 focus:ring-indigo-500 focus:border-indigo-500 dark:text-gray-200': mode == 'edit'}]"
        :placeholder="placeholder"
        v-model="model"
        v-if="!long"
      />

      <textarea
        :type="type"
        :readonly="mode == 'view'"
        name="label"
        class="
          block
          w-full
          sm:text-sm
          rounded-md
        "
        :class="[{'bg-transparent px-0 py-1 border-none shadow-none dark:text-gray-200 focus:ring-0 focus:border-none resize-none': mode == 'view'}, {'bg-white dark:bg-gray-700 shadow-sm border-gray-300 dark:border-gray-500 focus:ring-indigo-500 focus:border-indigo-500 dark:text-gray-200': mode == 'edit'}]"
        :placeholder="placeholder"
        v-model="model"
        v-if="long"
      ></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: "Input",
  props: {
    label: String,
    value: [String, Number],
    placeholder: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: 'edit'
    },
    type: {
      type: String,
      default: 'text'
    },
    long: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  data() {
    return {
      enabled: true,
    }
  },
}
</script>
